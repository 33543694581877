import React from 'react';
import {
  Easing,
  ReduceMotion,
  cancelAnimation,
  runOnJS,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

interface Options {
  duration: number;
  onEnd?(): void;
}

export const useAnimation = ({duration, onEnd}: Options) => {
  const progress = useSharedValue(0);
  const running = useSharedValue(false);

  const [isPaused, setIsPaused] = React.useState(false);

  useDerivedValue(() => {
    runOnJS(setIsPaused)(!running.value);
  }, [running.value]);

  const run = (d: number) => {
    running.value = true;

    progress.value = withTiming(
      1,
      {duration: d, easing: Easing.linear, reduceMotion: ReduceMotion.Never},
      finished => {
        if (!finished) return;

        running.value = false;

        if (!onEnd) return;

        runOnJS(onEnd)();
      },
    );
  };

  const start = () => {
    if (running.value) return;

    progress.value = 0;

    run(duration);
  };

  const stop = () => {
    cancelAnimation(progress);
    progress.value = 0;
    running.value = false;
  };

  const pause = () => {
    if (!running.value) return;

    cancelAnimation(progress);
    running.value = false;
  };

  const resume = () => {
    if (running.value) return;

    const remaining = duration * (1 - progress.value);

    run(remaining);
  };

  const restart = () => {
    stop();
    start();
  };

  return {
    isPaused,
    progress,
    start,
    stop,
    pause,
    resume,
    restart,
  };
};
