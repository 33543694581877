import {
  DefaultNavigatorOptions,
  ParamListBase,
  StackActionHelpers,
  StackNavigationState,
  StackRouter,
  StackRouterOptions,
  createNavigatorFactory,
  useIsFocused,
  useNavigationBuilder,
} from '@react-navigation/native';
import React from 'react';
import {
  GestureResponderEvent,
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import {Constants, appColors} from '../../constants';
import {createContainer} from '../../contexts/unstated-next';
import {useAnimation} from '../../navigation/components/useAnimation';
import {StoriesHeader} from '../../navigation/navigators/StoriesHeader';
import {toHome} from '../../navigation/navigators/util';
import {useSafeArea} from '../useSafeArea';

type StoryNavigationConfig = {
  contentStyle?: StyleProp<ViewStyle>;
};

export type StoryNavigationOptions = {
  headerTintColor?: string;
};

type StoryNavigationEventMap = Record<string, never>;

type Props = DefaultNavigatorOptions<
  ParamListBase,
  StackNavigationState<ParamListBase>,
  StoryNavigationOptions,
  StoryNavigationEventMap
> &
  StackRouterOptions &
  StoryNavigationConfig;

type Callback = () => void | Promise<void>;

interface Options {
  isDark?: boolean;
  pause(): void;
  resume(): void;
  pauseWhileExecuting: (cb: Callback) => Promise<void>;
}

const StoryNavigationContainer = createContainer<Options, Options>(o => o!);

export const useStoryNavigation = () => {
  return StoryNavigationContainer.useContainer();
};

const StoryNavigator = ({
  initialRouteName,
  children,
  screenOptions,
  contentStyle,
}: Props) => {
  const {state, navigation, descriptors, NavigationContent} =
    useNavigationBuilder<
      StackNavigationState<ParamListBase>,
      StackRouterOptions,
      StackActionHelpers<ParamListBase>,
      StoryNavigationOptions,
      StoryNavigationEventMap
    >(StackRouter, {
      children,
      screenOptions,
      initialRouteName,
    });
  const isFocused = useIsFocused();

  const index = React.useRef(state.index);

  index.current = state.index;

  const [longPressed, setLongPressed] = React.useState(false);

  const insets = useSafeArea();

  const {progress, isPaused, pause, resume, restart} = useAnimation({
    duration: 7000,
    onEnd: () => onNext(),
  });
  const isFinished =
    index.current === state.routeNames.length - 1 && progress.value === 1;

  React.useEffect(() => {
    if (isFocused) restart();
    else pause();
  }, [isFocused]);

  const onBack = () => {
    if (index.current < 1) return;

    navigation.pop();

    restart();
  };

  const onNext = () => {
    const next = state.routeNames[index.current + 1];

    if (!next) return;

    navigation.navigate(next);

    restart();
  };

  const onCloseButtonPress = () => {
    navigation.replace(...toHome);
  };

  const pauseWhileExecuting = React.useCallback(async (cb: Callback) => {
    pause();

    await cb();

    resume();
  }, []);

  const onPress = (event: GestureResponderEvent) => {
    const touchX = event.nativeEvent.pageX;
    const hasTouchedLeftSide = touchX < Constants.DIMENSIONS.WIDTH / 2;
    const hasTouchedRightSide =
      touchX > Constants.DIMENSIONS.WIDTH - Constants.DIMENSIONS.WIDTH / 2;

    if (hasTouchedLeftSide) return onBack();

    if (hasTouchedRightSide) {
      if (index.current + 1 >= state.routeNames.length)
        return onCloseButtonPress();

      return onNext();
    }
  };

  const playFromBeginning = () => {
    navigation.popToTop();

    restart();
  };

  const onPlaybackButtonPress = () => {
    isPaused ? (isFinished ? playFromBeginning() : resume()) : pause();
  };

  const onLongPress = () => {
    setLongPressed(true);
    pause();
  };

  const onPressOut = () => {
    setLongPressed(false);
    resume();
  };

  const options = descriptors[state.routes[state.index].key]?.options ?? {};
  const isDark = options.headerTintColor === appColors.black;

  return (
    <NavigationContent>
      <StoryNavigationContainer.Provider
        initialState={{isDark, pause, resume, pauseWhileExecuting}}>
        <Pressable
          {...{onPress, onLongPress, onPressOut}}
          style={[styles.content, contentStyle]}>
          <View style={[styles.header, {paddingTop: insets.top + 12}]}>
            <StoriesHeader
              {...{
                progress,
                onPlaybackButtonPress,
                onCloseButtonPress,
                isPaused,
                isDark,
              }}
              index={state.index}
              length={state.routeNames.length}
              visible={!longPressed}
            />
          </View>

          {state.routes.map((route, i) => (
            <View
              key={route.key}
              style={[
                StyleSheet.absoluteFill,
                {display: i === state.index ? 'flex' : 'none'},
              ]}>
              {descriptors[route.key].render()}
            </View>
          ))}
        </Pressable>
      </StoryNavigationContainer.Provider>
    </NavigationContent>
  );
};

const styles = StyleSheet.create({
  content: {flex: 1},
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    paddingHorizontal: 14,
    paddingBottom: 14,
  },
});

export default createNavigatorFactory<
  StackNavigationState<ParamListBase>,
  StoryNavigationOptions,
  StoryNavigationEventMap,
  typeof StoryNavigator
>(StoryNavigator);
